/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";
import { getFormFields, getBasinEndpoint } from "../../lib/functions";

import Layout from "../components/Layout";
import PageContent from "../components/PageContent";
import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";
import SideWrapper from "../components/SideWrapper";
import Sidenavs from "../components/Sidenavs";
import SponsorSlider from "../components/SponsorSlider";
import ContactForm from "../components/ContactForm";
import Calendar from "../components/EventsCalendar";

const Page = ({ data }) => {
  const { title, content, seo } = data.wpPage;
  const { form } = data.wpPage.afterContentForm;
  const { sidebarMenus } = data.wpPage.sidebarMenuSelect;
  const { events } = data.wp.acfOptionsEvents.events;

  return (
    <Layout seo={seo}>
      <PageWrapper>
        <ContentWrapper>
          <Calendar events={events} />
        </ContentWrapper>
        <SideWrapper>
          <Sidenavs menus={sidebarMenus} />
          <SponsorSlider sidebar />
        </SideWrapper>
      </PageWrapper>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      ...SEO
      sidebarMenuSelect {
        sidebarMenus {
          menu
        }
      }
      afterContentForm {
        form: afterContentForm
      }
    }
    wp {
      acfOptionsEvents {
        events {
          events {
            details
            endDate
            download {
              ...Button
            }
            endTime
            link: gzLink {
              ...Button
            }
            startDate
            startTime
            title
          }
        }
      }
    }
  }
`;
