import React from "react";
import tw, { styled } from "twin.macro";
import { getDayString, getMonthString } from "../../lib/functions";
import format from "date-fns/format";
import { getDate } from "date-fns";
import { Link } from "gatsby";

import InnerTitle from "./InnerTitle";

const Section = tw.section`w-full`;
const Events = tw.ul`flex flex-col gap-8`;

const Button = tw(
  Link
)`bg-secondary border-secondary text-white font-bold border-2 py-2 px-8 rounded focus:outline-none flex duration-500 ease-in-out relative self-start w-[min-content] whitespace-nowrap hover:(bg-transparent text-secondary border-secondary)`;

const styles = [
  tw`relative flex flex-row w-full gap-4 py-8 pl-12 pr-6 overflow-hidden duration-300 ease-in-out border-2 shadow-xl border-secondary rounded-2xl`,
];

const EventDiv = styled.div(() => [styles]);

const Day = styled.div`
  ${tw`absolute top-0 left-0 flex items-center justify-center h-full px-2 text-sm font-bold leading-none tracking-tighter text-white uppercase duration-300 ease-in-out bg-secondary bg-opacity-80`}
  writing-mode: vertical-rl;
  text-orientation: upright;
`;

const DateElem = tw.div`flex flex-col mb-6 font-din text-sm font-bold text-secondary`;
const Title = tw.h2`text-2xl  sm:text-4xl font-bold text-darkAlt font-din leading-none mb-1`;

const Event = ({ url, children }) => {
  return url ? (
    <EventLink to={url} target="_blank">
      {children}
    </EventLink>
  ) : (
    <EventDiv>{children}</EventDiv>
  );
};

const EventsCalendar = ({ events, ...rest }) => {
  const TODAY = new Date();
  return (
    <Section {...rest}>
      <InnerTitle>Upcoming Events</InnerTitle>
      <Events>
        {events &&
          events.map((event, i) => {
            let {
              startDate,
              endDate,
              startTime,
              endTime,
              title,
              details,
              link,
              download,
            } = event;

            startDate = new Date(startDate);
            if (endDate) {
              endDate = new Date(endDate);
            }

            // Check for long description and cut it after 100 words
            let detailsArray = details.split(" ");
            if (detailsArray.length > 100) {
              detailsArray = detailsArray.slice(0, 50);
              detailsArray.push("...");
            }
            details = detailsArray.join(" ");

            if ((endDate && endDate > TODAY) || startDate > TODAY) {
              return (
                <li key={i}>
                  <EventDiv>
                    <Day className="day">{format(startDate, "EEE")}</Day>
                    <div>
                      <Title>{title}</Title>
                      <DateElem>
                        <div tw="flex">
                          <span>{format(startDate, "MMMM, dd")}</span>
                          {!!endDate && (
                            <>
                              <span tw="px-1"> - </span>
                              <span>{format(endDate, "MMMM, dd")}</span>
                            </>
                          )}
                        </div>
                        {!!startTime && (
                          <div tw="flex">
                            <span>{startTime}</span>
                            {!!endDate && (
                              <>
                                <span tw="px-1"> - </span>
                                <span>{endTime}</span>
                              </>
                            )}
                          </div>
                        )}
                      </DateElem>
                      <p>{details}</p>
                      <div tw="flex gap-6 relative mt-6">
                        {!!link && (
                          <Button to={link.url} download target="_blank">
                            {link.title || "Registration Page"}
                          </Button>
                        )}
                        {!!download && (
                          <Button to={download.url} target="_blank">
                            {download.title}
                          </Button>
                        )}
                      </div>
                    </div>
                  </EventDiv>
                </li>
              );
            }
          })}
      </Events>
    </Section>
  );
};

export default EventsCalendar;
